<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as YARD_CONTRACTOR_TYPES } from '../../yard-contractor/store'

export default {
  name: 'ContractorEligibilityMixin',
  created () {
    if (!this.contractorEligibilityChoices || (this.contractorEligibilityChoices && this.contractorEligibilityChoices.length === 0)) {
      this.getContractorEligibilityChoices()
    }
  },
  computed: {
    ...mapGetters({
      contractorEligibilityChoices: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.GETTERS.contractorEligibilityChoices
    }),
    contractorEligibilityListOptions () {
      return this.prepareListForSelect(this.contractorEligibilityChoices, '0', ['1'])
    }
  },
  methods: {
    ...mapActions({
      getContractorEligibilityChoices: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.ACTIONS.getContractoreligibilitychoices
    }),
    selectContractorEligibility (item) {
      this.$set(this.form, 'contractor_eligibility', item.value)
      this.$set(this.form, 'get_contractor_eligibility_display', item.name)
    },
    clearContractorEligibility () {
      this.$set(this.form, 'contractor_eligibility', null)
      this.$set(this.form, 'get_contractor_eligibility_display', null)
    }
  }
}
</script>
