<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as YARD_CONTRACTOR_TYPES } from '../../yard-contractor/store'

export default {
  name: 'StaffEligibilityMixin',
  created () {
    if (!this.staffEligibilityChoices || (this.staffEligibilityChoices && this.staffEligibilityChoices.length === 0)) {
      this.getStaffEligibilityChoices()
    }
  },
  computed: {
    ...mapGetters({
      staffEligibilityChoices: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.GETTERS.staffEligibilityChoices
    }),
    staffEligibilityListOptions () {
      return this.prepareListForSelect(this.staffEligibilityChoices, '0', ['1'])
    }
  },
  methods: {
    ...mapActions({
      getStaffEligibilityChoices: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.ACTIONS.getStaffeligibilitychoices
    }),
    selectStaffEligibility (item) {
      this.$set(this.form, 'staff_eligibility', item.value)
      this.$set(this.form, 'get_staff_eligibility_display', item.name)
    },
    clearStaffEligibility () {
      this.$set(this.form, 'staff_eligibility', null)
      this.$set(this.form, 'get_staff_eligibility_display', null)
    }
  }
}
</script>
