<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as YARD_CONTRACTOR_TYPES } from '../../yard-contractor/store'

export default {
  name: 'PosEligibilityMixin',
  created () {
    if (!this.posEligibilityChoices || (this.posEligibilityChoices && this.posEligibilityChoices.length === 0)) {
      this.getPosEligibilityChoices()
    }
  },
  computed: {
    ...mapGetters({
      posEligibilityChoices: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.GETTERS.posEligibilityChoices
    }),
    posEligibilityListOptions () {
      return this.prepareListForSelect(this.posEligibilityChoices, '0', ['1'])
    }
  },
  methods: {
    ...mapActions({
      getPosEligibilityChoices: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.ACTIONS.getPoseligibilitychoices
    }),
    selectPosEligibility (item) {
      this.$set(this.form, 'pos_eligibility', item.value)
      this.$set(this.form, 'get_pos_eligibility_display', item.name)
    },
    clearPosEligibility () {
      this.$set(this.form, 'pos_eligibility', null)
      this.$set(this.form, 'get_pos_eligibility_display', null)
    }
  }
}
</script>
