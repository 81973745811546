<template>
  <div>
    <thux-table
      :instance="instance"
      :instance-name="instanceName"
      :order-by-dict="orderByDict"
      :goBack="goBack"
      :list="list"
      :fieldName="fieldName"
      :fields="fields"
      :fields-column="fieldsColumn"
      :select-all="selectAll"
      :selected-list="selectedList"
      :get-local-selected-all="getLocalSelectedAll"
      :local-selected-list="localSelectedList"
      @check-all="checkAll"
      @check-none="checkNone"
      @set-selected-row="setSelectedRow"
      :show-checks="showChecks"
      :showEditButton="showEditButton"
      @change-status="changeInstanceStatus"
      @sort-changed="orderTable"
      @remove-order-by="removeOrderFromTable"
      @open-edit-form="$emit('open-edit-form', $event)"
    >
      <template slot="ateco_codes" slot-scope="row">
        <div v-if="row.item.contractor_ateco_codes">
          <span>
            {{ row.item.contractor_ateco_codes }}
          </span>
        </div>
      </template>
      <template slot="other-buttons-actions-bottom" slot-scope="row">
        <b-btn
          variant="outline-primary"
          v-has-perms="removeContractorRolePerm"
          :title="'Remove contractor' | translate"
          @click="showConfirmModal(row.item)"
        >
          <i class="fas fa-times"></i>
        </b-btn>
        <b-btn
          variant="outline-primary"
          v-has-perms="downloadEligibilityRolePerm"
          :title="'Download eligibility documents' | translate"
          @click="showDownloadEligibilityModal(row.item)"
        >
          <i class="fas fa-file-download"></i>
        </b-btn>
      </template>
    </thux-table>
    <ConfirmDialog
      v-if="showDeleteModal && contractorToDelete.id"
      ref-id="modal-delete"
      :title="$t('Are you sure to remove this contractor?')"
      :button-confirm-text="'Remove' | translate"
      @confirm-dialog-close="closeConfirmModal"
      @confirm-dialog-select="deleteContractor"
    >
        <template slot="body">
          <b-table
            no-local-sorting
            show-empty
            striped
            hover
            responsive
            :items="[contractorToDelete]"
            :fields="contractorFields"
          >
            <template slot="cell(date_yard_entry)" slot-scope="row">
              {{ row.item.date_yard_entry | date }}
            </template>
            <template slot="cell(date_yard_exit)" slot-scope="row">
              {{ row.item.date_yard_exit | date }}
            </template>
            <template slot="cell(ateco_codes)" slot-scope="row">
              <div v-if="row.item.contractor_ateco_codes">
                <span
                  v-for="ateco in row.item.contractor_ateco_codes.split(',')"
                  :key="ateco"
                  class="tag"
                >
                  {{ ateco }}
                </span>
              </div>
            </template>
          </b-table>
      </template>
    </ConfirmDialog>
    <ConfirmDialog
      v-if="showownloadEligibilityModal && contractor.id"
      ref-id="modal-download"
      :title="$t('Which eligibility documents do you want to download?')"
      :button-confirm-text="'OK' | translate"
      confirm-btn-variant="primary"
      :confirm-btn-disabled="$v.printForm.$invalid"
      @confirm-dialog-close="closeConfirmModal"
      @confirm-dialog-select="printEligibilityDocuments"
    >
      <template slot="body">
        <owner-contact-emails-checkbox
          :selectedEmails='printForm.emails ? printForm.emails : []'
          @add-email="addOwnerEmailToList"
          @remove-email="removeOwnerEmailToList"
        ></owner-contact-emails-checkbox>
        <b-form>
          <b-form-row>
            <form-group-thux
              :validator="$v.printForm.emails"
              :label="'Insert emails' | translate"
              label-class="mb-2 font-weight-bold"
              class="col-12 mb-3"
            >
              <div class="input-group mb-2">
                <span
                  class="input-group-prepend"
                  :class="!areEmailsValid ? 'input-border-red' : ''"
                >
                  <span class="input-group-text">
                    <i class="fas fa-envelope"></i>
                  </span>
                </span>
                <b-form-tags
                  id="input-email"
                  tag-variant="tag"
                  add-button-variant="outline-primary"
                  :tag-validator="isEmailValid"
                  :state="areEmailsValid"
                  :placeholder="$t('Insert an email address')"
                  :invalid-tag-text="$t('Email not valid')"
                  :duplicate-tag-text="$t('Duplicate email')"
                  v-model="printForm.emails"
                  remove-on-delete
                >
                  <template slot="add-button-text">
                    <i class="fas fa-plus mr-1"></i>
                    {{ 'Add' | translate }}
                  </template>
                </b-form-tags>
              </div>
            </form-group-thux>
          </b-form-row>
          <b-form-row>
            <form-thux-horizontal-checkbox
              :validator="$v.printForm.company"
              :label-form="'Company eligibility' | translate"
              class-form="col-lg-4 mb-2"
              label-cols-lg="6"
              label-cols-sm="6"
              :value="printForm.company"
              @change="setCompanyEligibility"
            />
            <form-thux-horizontal-checkbox
              :validator="$v.printForm.freelance"
              :label-form="'Freelance eligibility' | translate"
              class-form="col-lg-4 mb-2"
              label-cols-lg="6"
              label-cols-sm="6"
              :value="printForm.freelance"
              @change="setFreelanceEligibility"
            />
            <form-thux-horizontal-checkbox
              :validator="$v.printForm.pos"
              :label-form="'Pos eligibility' | translate"
              class-form="col-lg-4 mb-2"
              label-cols-lg="6"
              label-cols-sm="6"
              :value="printForm.pos"
              @change="$set(printForm, 'pos', $event)"
            />
            <form-thux-horizontal-checkbox
              :validator="$v.printForm.staff"
              :label-form="'Staff eligibility' | translate"
              class-form="col-lg-4 mb-2"
              label-cols-lg="6"
              label-cols-sm="6"
              :value="printForm.staff"
              @change="$set(printForm, 'staff', $event)"
            />
            <form-thux-horizontal-checkbox
              :validator="$v.printForm.training"
              :label-form="'Staff training excel' | translate"
              class-form="col-lg-4 mb-2"
              label-cols-lg="6"
              label-cols-sm="6"
              :value="printForm.training"
              @change="$set(printForm, 'training', $event)"
            />
          </b-form-row>
        </b-form>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as YARD_CONTRACTOR_TYPES } from '../../yard-contractor/store'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxTableMixin from '../../../../components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '../../../../components/thux-table/thux-table-checks/ThuxTableChecksMixin'
import FormThuxHorizontalCheckbox from '@/components/form-thux-horizontal-components/FormThuxHorizontalCheckbox'
import ConfirmDialog from '../../../../components/ConfirmDialog'
import OwnerContactEmailsCheckbox from '../../../../components/OwnerContactEmailsCheckbox'

export default {
  name: 'YardContractorsTable',
  mixins: [ThuxTableMixin, ThuxTableChecksMixin],
  components: {
    ConfirmDialog,
    'form-thux-horizontal-checkbox': FormThuxHorizontalCheckbox,
    OwnerContactEmailsCheckbox
  },
  data () {
    return {
      contractorFields: [
        {
          key: 'contractor_name',
          label: this.$t('Name'),
          type: 'text',
          thClass: 'text-wrap',
          tdClass: 'text-wrap'
        },
        {
          key: 'date_yard_entry',
          label: this.$t('Date yard in'),
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },
        {
          key: 'date_yard_exit',
          label: this.$t('Date yard out'),
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },
        {
          key: 'ateco_codes',
          label: this.$t('Ateco codes')
        },
        {
          key: 'contractor_activity_desc',
          label: this.$t('Activity description'),
          tdClass: 'text-wrap',
          thClass: 'text-wrap'
        }
      ],
      showDeleteModal: false,
      contractorToDelete: {},
      showownloadEligibilityModal: false,
      contractor: {},
      printForm: {
        emails: []
      },
      email: '',
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      fields: [
        {
          key: 'status',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'status'
        },
        {
          key: 'contractor__name',
          label: this.$t('Name'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-wrap',
          sortable: true,
          value: 'contractor_name'
        },
        {
          key: 'date_yard_entry',
          label: this.$t('Date yard in'),
          type: 'date',
          thClass: 'text-nowrap text-right',
          tdClass: 'text-right',
          sortable: true,
          value: 'date_yard_entry'
        },
        {
          key: 'date_yard_exit',
          type: 'date',
          label: this.$t('Date yard out'),
          thClass: 'text-nowrap text-right',
          tdClass: 'text-right',
          sortable: true,
          value: 'date_yard_exit'
        },
        {
          key: 'ateco_codes',
          label: this.$t('Ateco codes'),
          custom: true
        },
        {
          key: 'contractor__activity_desc',
          label: this.$t('Activity description'),
          tdClass: 'text-wrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'contractor_activity_desc'
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      fieldsColumn: ['status', 'contractor__name', 'date_yard_entry', 'date_yard_exit', 'contractor__activity_desc'],
      // Permissions
      removeContractorRolePerm: 'yard_yardcontractor_destroy',
      downloadEligibilityRolePerm: 'yard_yardcontractor_download_eligibility'
    }
  },
  computed: {
    ...mapGetters({
      selectAll: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.GETTERS.selectAll,
      selectedList: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.GETTERS.selectedList
    }),
    showChecks () {
      return false
    },
    showDetailButton () {
      return false
    },
    showEditButton () {
      return hasPerm('yard_yardcontractor_update')
    },
    areEmailsValid () {
      if (this.printForm.emails && this.printForm.emails.length > 0 && this.isEmailValid(this.email)) return true
      return false
    }
  },
  methods: {
    ...mapMutations({
      setList: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.MUTATIONS.setList,
      setSelectedList: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.MUTATIONS.setSelectedList,
      setSelectAll: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.ACTIONS,
      delete: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.DETAIL.ACTIONS.delete
    }),
    isEmailValid (email) {
      this.$set(this, 'email', email)
      if (email === '' || !email) return true
      return this.reg.test(email)
    },
    showConfirmModal (instance) {
      this.$set(this, 'contractorToDelete', instance)
      this.$set(this, 'showDeleteModal', true)
    },
    closeConfirmModal () {
      this.$set(this, 'contractorToDelete', {})
      this.$set(this, 'showDeleteModal', false)
      this.$set(this, 'contractor', {})
      this.$set(this, 'showownloadEligibilityModal', false)
    },
    deleteContractor () {
      const promise = this.delete(this.contractorToDelete)
      promise.then(
        () => {
          setTimeout(() => {
            this.$emit('refresh-list')
            this.closeConfirmModal()
          }, 500)
        }
      )
    },
    showDownloadEligibilityModal (instance) {
      this.$set(this, 'printForm', {})
      this.$set(this, 'contractor', instance)
      this.$set(this, 'showownloadEligibilityModal', true)
    },
    setCompanyEligibility (evt) {
      if (evt && this.printForm.freelance) {
        this.$set(this.printForm, 'freelance', false)
      }
      this.$set(this.printForm, 'company', evt)
    },
    setFreelanceEligibility (evt) {
      if (evt && this.printForm.company) {
        this.$set(this.printForm, 'company', false)
      }
      this.$set(this.printForm, 'freelance', evt)
    },
    printEligibilityDocuments () {
      const formData = Object.assign({}, this.printForm)
      formData.id = this.contractor.id
      formData.action = 'download-eligibility'
      formData.simpleFileName = `${this.$t('Eligibility')}_${this.contractor.yard_name.replace(/ /g, '')}_${this.contractor.contractor_name.replace(/ /g, '')}.zip`
      this.downloadEligibility(formData).then(
        () => {
          this.$set(this, 'showownloadEligibilityModal', false)
        }
      )
    },
    addOwnerEmailToList (email) {
      let emailList = Object.assign([], this.printForm.emails)
      if (emailList === undefined) {
        emailList = []
      }
      emailList.push(email)
      this.$set(this.printForm, 'emails', emailList)
    },
    removeOwnerEmailToList (email) {
      const emailList = Object.assign([], this.printForm.emails)
      const index = emailList.indexOf(email)
      if (index > -1) {
        emailList.splice(index, 1)
      }
      this.$set(this.printForm, 'emails', emailList)
    }
  },
  validations: {
    printForm: {
      emails: {
        required,
        minLength: minLength(1)
      },
      freelance: {},
      company: {},
      staff: {},
      pos: {},
      training: {}
    }
  }
}
</script>
