<template>
  <div v-if="!showFullList" v-has-perms="rolePerm">
    <portal-target
      v-show="showList"
      name="layout-main"
      class="layout-main panel-scroll-full"
    >
      <div class="panel-list panel-fixed">
        <div class="panel-header">
          <h1 class="page-header mr-2 d-flex align-items-center">
            {{ titleWithYardName | translate }}
          </h1>
          <ol class="breadcrumb pull-right">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'dashboard'}">
                {{"Dashboard"}}
              </router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link :to="{ name: YARD_ROUTES.YARD_LIST }">
                {{$t("Yards")}}
              </router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link :to="{ name: YARD_ROUTES.YARD_DETAIL, params: { id: $route.params.id } }">
                {{$t("Yard")}}
              </router-link>
            </li>
            <li class="breadcrumb-item">{{ title | translate }}</li>
          </ol>
        </div>
        <component-edit
          v-if="showEditForm && editComponent"
          :id="componentId"
          :contractors="list.results"
          :yard="$route.params.id"
          :editParams="editParams"
          @on-submit='refreshListAfterSubmit'
          @close-form='closeEditForm'
        />

        <advanced-search
          v-show="showAdvancedSearch"
          v-if="toggleAdvancedSearch"
          :fields="searchFields"
          :reset="reset"
          @search="addFiltersToInstance"
          @reset="removeFiltersToInstance"
          @active="activeFilters = $event"
        />
        <panel :title="title" :no-button="true">
          <thux-list-component
            :fields="getFields"
            :pagination="getPaginationFields"
            @select-per-page="changePerPage"
            @change-page="changePage"
            :action-select="getActionSelectFields"
            @select-action="setAction"
            @select-or-deselect-all-queryset="selectOrDeselectAllQueryset"
            @do-action="doAction($event, fieldName)"
            :advanced-search="getAdvancedSearchFields"
            @show-advanced-search="openAdvancedSearch"
            @open-edit-form="openEditForm()"
            @open-detail-form="openDetailForm()"
          >
            <template slot="body">
              <component-table
                :list="list.results"
                :goBack="goBack"
                :params="tableParams"
                @open-edit-form="openEditForm"
                @open-detail-form="openDetailForm"
                @refresh-list="refreshListAfterSubmit"
              />
            </template>
            <template slot="other-headers-buttons">
              <b-btn
                variant="primary"
                v-has-perms="evaluationDocListRolePerm"
                class="ml-2"
                @click="showYardContractorFullList"
              >
                <i class="fas fa-search" :alt="'Show' | translate" />
                {{ 'Show business valuation' | translate }}
              </b-btn>
            </template>
          </thux-list-component>
        </panel>
      </div>
    </portal-target>
    <router-view />
    <portal-target
      v-if="showCommonFilters"
      v-show="showList"
      name="layout-sidebar"
      class="layout-side panel-scroll-full"
    >
      <button
        @click.prevent="closeRightSidebar"
        class="btn btn-primary text-uppercase w-100 mb-4"
        :aria-label="$t('Close')"
      >
        <i class="fas fa-times" />
        {{ $t('Hide sidebar') }}
      </button>
      <common-filters />
    </portal-target>
  </div>
  <div v-else>
    <full-table
      :yard="$route.params.id"
      :list="contractorsEvaluations"
      :docs="evaluationDocList"
      @hide="hideYardContractorFullList" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { ROUTES as YARD_ROUTES } from '../router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../../components/thux-list/ThuxListMixin'

import YardContractorsTable from './YardContractorsTable'
import YardContractorsFullTable from './YardContractorsFullTable'
import YardContractorEdit from './YardContractorEdit'
import { TYPES as YARD_CONTRACTOR_TYPES } from '../../yard-contractor/store'
import { TYPES as DOC_TYPES } from '../../../document/document/store'
import { vue } from '../../../../main'
import { TYPES as YARD_TYPES } from '../store'

export default {
  name: 'YardContractorsList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': YardContractorsTable,
    'component-edit': YardContractorEdit,
    'full-table': YardContractorsFullTable
  },
  data () {
    return {
      YARD_ROUTES,
      title: this.$t('Contractors'),
      showCommonFilters: false,
      showFullList: false,
      searchFields: [
        { key: 'filter__contractor__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 6 },
        { key: 'filter__date_yard_entry__icontains', placeholder: this.$t('Date yard entry'), type: 'date', col: 6 },
        { key: 'filter__technician__supplier_contacts__ateco_codes__name__icontains', placeholder: this.$t('Ateco code'), type: 'text', col: 6 },
        { key: 'filter__contractor__activity_desc__icontains', placeholder: this.$t('Activity description'), type: 'text', col: 12 }
      ],
      // Permissions
      rolePerm: ['yard_yardcontractor_list'],
      evaluationDocListRolePerm: 'yard_yardcontractor_contractors_evaluation',
      actionEnablePermission: ['yard_yardcontractor_enable'],
      actionDisablePermission: ['yard_yardcontractor_disable']
    }
  },
  created () {
    this.yardRetrieve({ id: this.$route.params.id })
  },
  computed: {
    ...mapGetters({
      ...YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.GETTERS,
      evaluationDocList: DOC_TYPES.GENERIC.document.document.LIST.GETTERS.evaluationDocList,
      yardDetail: YARD_TYPES.GENERIC.yard.yard.DETAIL.GETTERS.detail
    }),
    titleWithYardName () {
      if (this.yardDetail && this.yardDetail.code) {
        return `${this.$t('Contractors')} ${this.yardDetail.name ? this.yardDetail.name : this.yardDetail.customer_name} (${this.yardDetail.code})`
      }
      if (this.list && this.list.results && this.list.results.length > 0) {
        const yardName = this.list.results[0].yard_name ? `(${this.list.results[0].yard_name})` : ''
        return `${this.$t('Contractors')} ${yardName}`
      }
      return this.$t('Contractors')
    },
    showList () {
      return true
    },
    canSeeActionSelect () {
      return false
    },
    canAddInstance () {
      return hasPerm('yard_yardcontractor_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.MUTATIONS.setSelectedList,
      setSelectAll: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.ACTIONS,
      getEvaluationDocList: DOC_TYPES.GENERIC.document.document.LIST.ACTIONS.getEvaluationdoclist,
      yardRetrieve: YARD_TYPES.GENERIC.yard.yard.DETAIL.ACTIONS.retrieve
    }),
    showYardContractorFullList () {
      vue.$store.commit('componentLoading', true)
      const promises = [
        this.getEvaluationDocList(),
        this.getContractorsevaluations({ filter__yard: this.$route.params.id, no_page: 'no_page' })
      ]
      Promise.all(promises).then(() => {
        this.$set(this, 'showFullList', true)
      })
    },
    hideYardContractorFullList () {
      vue.$store.commit('componentLoading', true)
      this.setFilters({ filter__yard: this.$route.params.id }).then(() => {
        this.$set(this, 'showFullList', false)
      })
    },
    getComponentList () {
      this.setFilters({ filter__yard: this.$route.params.id })
    },
    refreshListAfterSubmit () {
      this.getComponentList()
      this.closeEditForm()
    },
    closeEditForm () {
      this.$set(this, 'showDetailForm', false)
      this.$set(this, 'showEditForm', false)
    }
  }
}
</script>
