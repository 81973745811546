<template>
  <panel :title="title" noButton="true" v-has-perms="rolePerm">
    <b-form>
      <b-form-row>
        <form-thux-horizontal-autocomplete
          :validator="$v.form.contractor"
          :label-form="'Contractor' | translate"
          class-form="col-lg-6 mb-2 mt-2"
          :disabled="!formEditable"
          :label-search="'name'"
          labelColsSm="3"
          labelColsLg="3"
          :value="form.contractor ? { id: form.contractor, name: form.contractor_typology_name ? `${form.contractor_name} (${form.contractor_typology_name})` :  form.contractor_name} : null"
          :options="contractorListOptions"
          :placeholder="$t('Search by name')"
          :placeholder-field="form.contractor_name"
          @search="onSearchContractor"
          @select="selectContractor"
          @clear="clearContractor"
        />
      </b-form-row>
      <b-form-row>
        <form-thux-horizontal-datetime
          :validator="$v.form.date_yard_entry"
          :label-form="'Date yard entry' | translate"
          labelColsSm="3"
          labelColsLg="3"
          class-form="col-lg-6 my-2"
          type="date"
          :disabled="!formEditable"
          :value="dateYardEntry"
          :placeholder="$t('Enter the date yard entry')"
          @change="dateChange($event, 'date_yard_entry')"
        />
        <form-thux-horizontal-datetime
          :validator="$v.form.date_yard_exit"
          :label-form="'Date yard exit' | translate"
          labelColsSm="3"
          labelColsLg="3"
          class-form="col-lg-6 my-2"
          type="date"
          :disabled="!formEditable"
          :value="dateYardExit"
          :placeholder="$t('Enter the date yard exit')"
          @change="dateChange($event, 'date_yard_exit')"
        />
      </b-form-row>
      <b-form-row>
        <form-thux-horizontal-multiselect
          :label-form="'Ateco codes' | translate"
          class-form="col-lg-12 my-2"
          label-form-class="col-half-width"
          label-search="id"
          labelColsSm="2"
          labelColsLg="2"
          :disabled="true"
          :value="form.contractor_ateco_codes"
          :options="form.contractor_ateco_codes || []"
        />
      </b-form-row>
      <b-form-row>
        <form-thux-horizontal-select
          :validator="$v.form.contractor_eligibility"
          :external-errors="errors['contractor_eligibility']"
          :label-form="'Contractor eligibility' | translate"
          class="col-lg-6 mb-2 mt-2"
          labelColsSm="3"
          labelColsLg="3"
          :disabled="!formEditable"
          label-search="name"
          :customize-option="true"
          :value="form.contractor_eligibility ? { value: form.contractor_eligibility, name: form.get_contractor_eligibility_display } : null"
          :options="contractorEligibilityListOptions"
          @select="selectContractorEligibility"
          @clear="clearContractorEligibility"
        />
        <form-thux-horizontal-select
          :validator="$v.form.staff_eligibility"
          :external-errors="errors['staff_eligibility']"
          :label-form="'Staff eligibility' | translate"
          class="col-lg-6 mb-2 mt-2"
          labelColsSm="3"
          labelColsLg="3"
          :disabled="!formEditable"
          label-search="name"
          :customize-option="true"
          :value="form.staff_eligibility ? { value: form.staff_eligibility, name: form.get_staff_eligibility_display } : null"
          :options="staffEligibilityListOptions"
          @select="selectStaffEligibility"
          @clear="clearStaffEligibility"
        />
      </b-form-row>
      <b-form-row>
        <form-thux-horizontal-select
          :validator="$v.form.pos_eligibility"
          :external-errors="errors['pos_eligibility']"
          :label-form="'Pos eligibility' | translate"
          class="col-lg-6 mb-2 mt-2"
          labelColsSm="3"
          labelColsLg="3"
          :disabled="!formEditable"
          label-search="name"
          :customize-option="true"
          :value="form.pos_eligibility ? { value: form.pos_eligibility, name: form.get_pos_eligibility_display } : null"
          :options="posEligibilityListOptions"
          @select="selectPosEligibility"
          @clear="clearPosEligibility"
        />
        <form-thux-horizontal-checkbox
          :validator="$v.form.in_notification"
          :external-errors="errors['in_notification']"
          :label-form="'In notification' | translate"
          class-form="col-lg-6 mb-2"
          label-cols-lg="3"
          label-cols-sm="3"
          :value="form.in_notification"
          :disabled="!formEditable"
          @change="$set(form, 'in_notification', $event)"
        />
      </b-form-row>
      <b-form-row>
        <form-thux-horizontal-text-area
          :label-form="'Activity description' | translate"
          class-form="col-12 my-2"
          label-form-class="col-half-width"
          labelColsSm="1"
          labelColsLg="1"
          rows="2"
          max-rows="6"
          :disabled="true"
          :value="form.contractor_activity_desc"
        />
      </b-form-row>
      <b-form-row>
        <form-thux-horizontal-text-area
          :validator="$v.form.note"
          :label-form="'Note' | translate"
          class-form="col-12 my-2"
          label-form-class="col-half-width"
          labelColsSm="1"
          labelColsLg="1"
          rows="2"
          max-rows="6"
          :disabled="!formEditable"
          :value="form.note"
          @change="$set(form, 'note', $event)"
        />
      </b-form-row>
    </b-form>
    <div class="mt-3 f-w-600 d-flex justify-content-end">
      <b-button
        class="mr-3"
        variant="default"
        @click.prevent="formEditable ? cancel() : closeForm()">
        {{ formEditable ? 'Cancel' : 'Close' | translate }}
      </b-button>
      <b-button
        variant="primary"
        v-has-perms="editRolePerm"
        @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
        :disabled="$v.form.$invalid">
        {{ formEditable ? 'Save' : 'Edit' | translate }}
      </b-button>
    </div>
  </panel>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as YARD_CONTRACTOR_TYPES } from '../../yard-contractor/store'

import ThuxDetailPanelMixin from '../../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import ContractorMixin from '../mixins/ContractorMixin'
import moment from 'moment'
import ContractorEligibilityMixin from '../mixins/ContractorEligibilityMixin'
import StaffEligibilityMixin from '../mixins/StaffEligibilityMixin'
import PosEligibilityMixin from '../mixins/PosEligibilityMixin'

export default {
  name: 'YardContractorEdit',
  props: {
    yard: { required: true }
  },
  mixins: [
    ThuxDetailPanelMixin,
    ContractorMixin,
    ContractorEligibilityMixin,
    StaffEligibilityMixin,
    PosEligibilityMixin
  ],
  data () {
    return {
      pageName: 'Contractor',
      dateYardEntry: undefined,
      dateYardExit: undefined,
      rolePerm: 'yard_yardcontractor_retrieve',
      editRolePerm: 'yard_yardcontractor_update'
    }
  },
  computed: {
    ...mapGetters({
      ...YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.DETAIL.GETTERS
    })
  },
  methods: {
    ...mapActions({
      ...YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.DETAIL.ACTIONS
    }),
    setForm () {
      const form = Object.assign({}, this.detail)
      if (form.date_yard_entry) {
        this.$set(this, 'dateYardEntry', moment(new Date(form.date_yard_entry)))
      } else {
        this.$set(this, 'dateYardEntry', null)
      }
      if (form.date_yard_exit) {
        this.$set(this, 'dateYardExit', moment(new Date(form.date_yard_exit)))
      } else {
        this.$set(this, 'dateYardExit', null)
      }
      if (form.contractor_ateco_codes) {
        form.contractor_ateco_codes = form.contractor_ateco_codes.split(',').map((ateco) => {
          return {
            id: ateco.trim(),
            name: ateco.trim()
          }
        })
      }
      if (form.in_notification === null) {
        this.$set(form, 'in_notification', false)
      }
      this.$set(this, 'form', form)
    },
    getFormData () {
      const formData = Object.assign({}, this.form)
      if (formData.in_notification === null) {
        formData.in_notification = false
      }
      formData.yard = this.yard
      return formData
    },
    selectContractor (item) {
      this.setContractorList({})
      this.$set(this.form, 'contractor', item.id)
      this.$set(this.form, 'contractor_name', item.name)
      this.$set(this.form, 'contractor_ateco_codes', item.ateco_codes)
      this.$set(this.form, 'contractor_activity_desc', item.activity_desc)
    },
    clearContractor () {
      this.$set(this.form, 'contractor', null)
      this.$set(this.form, 'contractor_name', null)
      this.$set(this.form, 'contractor_ateco_codes', null)
      this.$set(this.form, 'contractor_activity_desc', null)
    },
    dateChange (evt, fieldName, format = 'YYYY-MM-DD') {
      const date = evt.date
      if (date) {
        this.$set(this.form, fieldName, date.format(format))
      } else {
        this.$set(this.form, fieldName, null)
      }
    }
  },
  validations: {
    form: {
      contractor: { required },
      date_yard_entry: {},
      date_yard_exit: {},
      in_notification: {},
      contractor_eligibility: {},
      staff_eligibility: {},
      pos_eligibility: {},
      note: {}
    }
  }
}
</script>
