<template>
  <portal-target
      name="layout-main"
      class="layout-main panel-scroll-full"
    >
    <div class="panel-list panel-fixed">
      <div class="panel-header">
        <h1 class="page-header mr-2 d-flex align-items-center">
          {{ titleWithYardName | translate }}
          <div class="page-header-actions">
            <b-btn
              class="ml-2"
              variant="outline-primary"
              v-has-perms="sendEmailValuationRolePerm"
              :title="'Send email for business valuation' | translate"
              @click="showSendEmailModal = true"
            >
              <i class="fas fas fa-envelope"></i>
            </b-btn>
          </div>
        </h1>
        <ol class="breadcrumb pull-right">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'dashboard'}">
              {{"Dashboard"}}
            </router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: YARD_ROUTES.YARD_LIST }">
              {{$t("Yards")}}
            </router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: YARD_ROUTES.YARD_DETAIL, params: { id: $route.params.id } }">
              {{$t("Yard")}}
            </router-link>
          </li>
          <li class="breadcrumb-item">
            <span class="clickable" @click="$emit('hide')">{{ 'Contractors' | translate }}</span>
          </li>
          <li class="breadcrumb-item">
            {{ 'Business valuation' | translate }}
          </li>
        </ol>
      </div>
      <panel :title="$t('Business valuation')" :no-button="true">
      <div class="col">
        <table class="table b-table table-striped table-hover">
          <thead class="">
            <tr class="">
              <th class="table-b-table-default text-nowrap">
                <div>
                    {{ 'Business Name' | translate }}
                </div>
              </th>
              <th class="table-b-table-default text-nowrap">
                <div>
                    {{ 'In notification' | translate }}
                </div>
              </th>
              <th class="table-b-table-default" v-for="doc in docs" :key="doc.id">
                <div v-if="doc.acronym">{{doc.acronym}}</div>
                <div v-else>{{doc.name}}</div>
              </th>
              <th class="table-b-table-default text-nowrap">
                <div>
                    {{ 'Contractor eligibility' | translate }}
                </div>
              </th>
              <th class="table-b-table-default text-nowrap">
                <div>
                    {{ 'Staff eligibility' | translate }}
                </div>
              </th>
              <th class="table-b-table-default text-nowrap">
                <div>
                    {{ 'Pos eligibility' | translate }}
                </div>
              </th>
              <th class="table-b-table-default text-wrap">
                <div>
                    {{ 'Note' | translate }}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="" v-for="contr in list" :key="contr.id">
              <td>
                <div>
                  <span>{{contr.contractor_name}}</span>
                </div>
              </td>
              <td class="text-nowrap">
                <div class="table-indicators">
                  <span v-if="contr.in_notification">
                    <i class="far fa-check-circle text-green" />
                  </span>
                  <span v-else>
                    <i class="far fa-times-circle text-red" />
                  </span>
                </div>
              </td>
              <td class="" v-for="(el, idx) in contr.yard_evaluation_documents" :key="idx">
                <div>
                  <span v-if="el.date" :class="`fw-600 tag tag-${DOCUMENT_UPLOADDOCUMENT_STATUS_COLORS[el.status]}`">{{el.date | date}}</span>
                  <span v-else :class="`fw-600 tag tag-${DOCUMENT_UPLOADDOCUMENT_STATUS_COLORS[el.status]}`"> {{$t('Requested')}} </span>
                </div>
              </td>
              <td class="text-nowrap">
                <span v-if="contr.contractor_eligibility" :class="`fw-600 tag tag-${YARD_YARDCONTRACTOR_COLORS[contr.contractor_eligibility]}`">
                  {{ contr.get_contractor_eligibility_display }}
                </span>
              </td>
              <td class="text-nowrap">
                <span v-if="contr.staff_eligibility" :class="`fw-600 tag tag-${YARD_YARDCONTRACTOR_COLORS[contr.staff_eligibility]}`">
                  {{ contr.get_staff_eligibility_display }}
                </span>
              </td>
              <td class="text-nowrap">
                <span v-if="contr.pos_eligibility" :class="`fw-600 tag tag-${YARD_YARDCONTRACTOR_COLORS[contr.pos_eligibility]}`">
                  {{ contr.get_pos_eligibility_display }}
                </span>
              </td>
              <td class="text-wrap">
                <span>{{contr.note}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      </panel>
    </div>
    <send-email-modal
      v-if="showSendEmailModal && this.yard"
      v-has-perms="sendEmailValuationRolePerm"
      :show-owner-emails='true'
      :title="$t('Send email for business valuation')"
      @submit="sendBusinessValuationEmail"
      @close="showSendEmailModal = false"
    />
  </portal-target>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as YARD_CONTRACTOR_TYPES } from '../../yard-contractor/store'
import { ROUTES as YARD_ROUTES } from '../router'

import ThuxTableMixin from '../../../../components/thux-table/ThuxTableMixin'
import { DOCUMENT_UPLOADDOCUMENT_STATUS_COLORS, YARD_YARDCONTRACTOR_COLORS } from '../../../../const'
import SendEmailModal from '../../../../components/SendEmailModal'
import JobMixin from '../../../../components/job-button/JobMixin'

export default {
  name: 'YardContractorsFullTable',
  props: ['yard', 'list', 'docs'],
  mixins: [ThuxTableMixin, JobMixin],
  components: {
    SendEmailModal
  },
  data () {
    return {
      YARD_ROUTES,
      YARD_YARDCONTRACTOR_COLORS,
      DOCUMENT_UPLOADDOCUMENT_STATUS_COLORS,
      showSendEmailModal: false,
      fields: [
        {
          key: 'contractor__name',
          label: this.$t('Business Name'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'contractor_name'
        },
        {
          key: 'in_notification',
          label: this.$t('In notification'),
          type: 'boolean',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'in_notification'
        },
        {
          key: 'durc',
          label: this.$t('Durc'),
          custom: true
        },
        {
          key: 'insurance',
          label: this.$t('Insurance'),
          type: 'date',
          custom: true
        },
        {
          key: 'visura',
          label: this.$t('Visura'),
          type: 'date',
          custom: true
        },
        {
          key: 'contractor_eligibility',
          label: this.$t('Contractor eligibility'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          custom: true
        },
        {
          key: 'staff_eligibility',
          label: this.$t('Staff eligibility'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          custom: true
        },
        {
          key: 'pos_eligibility',
          label: this.$t('Pos eligibility'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          custom: true
        },
        {
          key: 'note',
          label: this.$t('Note'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'note'
        }
      ],
      // Permissions
      sendEmailValuationRolePerm: 'yard_yardcontractor_send_emails_business_valuation_job'
    }
  },
  computed: {
    ...mapGetters({
      selectAll: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.GETTERS.selectAll,
      selectedList: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.GETTERS.selectedList,
      apiUrl: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.GETTERS.apiUrl
    }),
    titleWithYardName () {
      if (this.list && this.list && this.list.length > 0) {
        return `${this.$t('Business valuation')} (${this.list[0].yard_name})`
      }
      return this.$t('Business valuation')
    },
    showChecks () {
      return false
    },
    showDetailButton () {
      return false
    },
    showEditButton () {
      return false
    },
    evalFields () {
      const res = [
        {
          key: 'contractor__name',
          label: this.$t('Business Name'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'contractor_name'
        },
        {
          key: 'in_notification',
          label: this.$t('In notification'),
          type: 'boolean',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'in_notification'
        }
      ]
      if (this.docs && Array.isArray(this.docs)) {
        this.docs.forEach((doc) => {
          res.push({
            key: 'id',
            label: doc.acronym,
            type: 'date',
            sortable: false,
            custom: false
          })
        })
      }
      res.push({
        key: 'contractor_eligibility',
        label: this.$t('Contractor eligibility'),
        tdClass: 'text-nowrap',
        thClass: 'text-nowrap',
        custom: true
      })
      res.push({
        key: 'staff_eligibility',
        label: this.$t('Staff eligibility'),
        tdClass: 'text-nowrap',
        thClass: 'text-nowrap',
        custom: true
      })
      res.push({
        key: 'pos_eligibility',
        label: this.$t('Pos eligibility'),
        tdClass: 'text-nowrap',
        thClass: 'text-nowrap',
        custom: true
      })
      res.push({
        key: 'note',
        label: this.$t('Note'),
        tdClass: 'text-nowrap',
        thClass: 'text-nowrap',
        sortable: true,
        value: 'note'
      })
      return res
    }
  },
  methods: {
    ...mapMutations({
      setList: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.MUTATIONS.setList,
      setSelectedList: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.MUTATIONS.setSelectedList,
      setSelectAll: YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...YARD_CONTRACTOR_TYPES.GENERIC.yard.yardcontractor.LIST.ACTIONS
    }),
    sendBusinessValuationEmail (emails) {
      this.$set(this, 'apiCallJobUrl', `${this.apiUrl}/send-emails-business-valuation-job/`)
      this.doJob({ yard_id: this.yard, emails: emails })
    },
    afterExecuteJob () {
      this.$set(this, 'showSendEmailModal', false)
    }
  }
}
</script>
